import styled from 'styled-components';
// Custom Components
import Section from '../../../reusable/Section';
import Blob from '../../../reusable/Blob';
import Carousel from '../../../reusable/Carousel';

export default function Home() {
  return (
    <Section id='dfktools'>
      <Blob pathIndex={1} />
      <Content>
        <ContentTitle>
          DFK Tools
          <SiteURL href='https://dfktools.com/' target='_blank' style={{ display: 'block' }}>
            www.dfktools.com
          </SiteURL>
        </ContentTitle>
        <Carousel images={['/dfktools/screenshot-1.png', '/dfktools/screenshot-2.png']} />
        <P>
          Provides a series of tools built around the rising crypto game{' '}
          <A href='https://defikingdoms.com/' target='_blank'>
            DefiKingdoms
          </A>
          . Tools NFT price analytics, price alerts, automated buying/selling, and more. This
          project is an exciting intersect between modern web development and next generation
          blockchain technologies.
        </P>
        <P>
          The front end is built using React. To communicate with the server I use Apollo
          Client, which is a library for graphQL.
        </P>
        <P>
          The back end uses MongoDB for the database and Apollo Server to provide an API. It is
          comprised of various microservices.
        </P>
        <ul style={{ marginTop: 0 }}>
          <li>
            <b>Event Service</b>: Indexes smart contract events in a database
          </li>
          <li>
            <b>Alerts Service</b>: Sends users NFT price alerts via Discord
          </li>
          <li>
            <b>Bidding Service</b>: Will automatically purchase NFTs which meet specified price
            thresholds and criteria
          </li>
          <li>
            <b>Graph API</b>: Front end API. Utilizes mongoDB aggregation pipelines to
            efficiently provide analytical data
          </li>
        </ul>
      </Content>
    </Section>
  );
}

const Content = styled.div`
  padding: 2rem;
  max-width: 700px;
  text-align: justify;
  font-weight: 400;
  overflow: hidden;
`;
const ContentTitle = styled.h1`
  margin-top: 0;
`;
const P = styled.p`
  margin: 0.5rem 0;
`;
const A = styled.a`
  color: black;

  &:hover {
    color: #00b899;
  }
`;
const SiteURL = styled.a`
  display: block;
  font-size: 14px;
  color: black;

  &:hover {
    color: #00b899;
  }
`;
