import { useState, useEffect } from 'react';

export default function (parentId) {
  const [currentSectionId, setCurrentSectionId] = useState(null);

  useEffect(() => {
    const handleIntersection = events => {
      for (const event of events) {
        if (event.isIntersecting) setCurrentSectionId(event.target.id);
        break;
      }
    };
    let observer = new IntersectionObserver(handleIntersection, { threshold: 0.9 });

    const sections = document.getElementById(parentId).children;

    for (const section of sections) {
      observer.observe(section);
    }
  }, [parentId]);

  return currentSectionId;
}
