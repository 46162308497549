import { useState } from 'react';
import styled from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export default function ({ images }) {
  const options = {
    showStatus: false,
    autoPlay: false,
    swipeable: true,
    emulateTouch: true,
  };

  return (
    <Container>
      <Carousel {...options}>
        {images.map(src => (
          <Image src={src} />
        ))}
      </Carousel>
    </Container>
  );
}

const Image = ({ src }) => {
  return (
    <ImgContainer>
      <Img src={src} />
    </ImgContainer>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Img = styled.img``;

const ImgContainer = styled.div`
  border: 2px solid #d4d4d4;
  border-radius: 8px;
  overflow: hidden;
`;
