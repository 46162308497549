import styled from 'styled-components';
// Custom Components
import Section from '../../../reusable/Section';
import Blob from '../../../reusable/Blob';
import Carousel from '../../../reusable/Carousel';

export default function Home() {
  return (
    <Section id='mesh'>
      <Blob pathIndex={2} />
      <Content>
        <ContentTitle>
          Mesh
          <SiteURL
            href='https://mesh-mrp-demo.web.app/'
            target='_blank'
            style={{ display: 'block' }}>
            mesh-mrp-demo.web.app
          </SiteURL>
        </ContentTitle>
        <Carousel
          images={[
            '/mesh/screenshot-1.png',
            '/mesh/screenshot-2.png',
            '/mesh/screenshot-3.png',
          ]}
        />
        <P>
          Mesh syncs e-commerce sales and uses this information to inform a manufacturing
          workflow. Provides full visibility and control of the shop floor from the moment a
          customer makes a purchase to the time the product leaves the door. The production
          manager plans operations. Workstation views allow employees to mark their tasks
          complete as they do them. Stations integrate with various equipment, including
          barcode scanners, label printers, and part marking machines.
        </P>
        <P>
          The front end uses React. It uses Apollo Client to communicate with the server via
          graphQL. Most of the equipment integrations are in Javascript, but the part marking
          machine utilizes Python.
        </P>
        <P>
          The back end is built on node.js, with MongoDB as the database. The API is hosted
          using Apollo Server, which utilizes graphQL.
        </P>
      </Content>
    </Section>
  );
}

const Content = styled.div`
  padding: 2rem;
  max-width: 700px;
  text-align: justify;
  font-weight: 400;
  overflow: hidden;
`;
const ContentTitle = styled.h1`
  margin-top: 0;
`;
const P = styled.p`
  margin: 0.5rem 0;
`;
const SiteURL = styled.a`
  display: block;
  font-size: 14px;
  color: black;

  &:hover {
    color: #00b899;
  }
`;
